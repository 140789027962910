.Toastify__toast-container {
  z-index: 999999 !important;
}

.Toastify__toast {
  min-height: auto;
}

.Toastify__close-button {
  margin-right: -12px !important;
  margin-top: -12px !important;
}

.single-date-picker .SingleDatePicker,
.single-date-picker .SingleDatePickerInput,
.single-date-picker .DateInput {
  width: 100% !important;
}

.single-date-picker .DateInput_input {
  border: 1px solid rgba(209, 213, 219, 1) !important;
  border-radius: 0.375rem !important;
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 1.25rem !important;
  width: 100% !important;
}

.multi-date-picker .DateRangePickerInput {
  border: 1px solid rgba(209, 213, 219, 1) !important;
  border-radius: 0.375rem !important;
}

.multi-date-picker .DateInput {
  background: none !important;
}

.multi-date-picker .DateRangePickerInput_arrow {
  padding: 0 5px;
}

.multi-date-picker .DateInput_input {
  border-radius: 0.375rem !important;
  border: none !important;
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 1.25rem !important;
}
